.appContainer {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.formContainer {
  width: 20%;
  background-color: #f3f3f3;
  padding: 40px;
  border-radius: 10px;
}

.keyDisplay {
  cursor: pointer;
  margin-top: 10px;
  padding: 10px;
  background-color: #D3D3D3;
  border-radius: 5px;
}

.copySuccess {
  margin-top: 10px;
  padding: 10px;
  background-color: #ADD8E6;
  border-radius: 5px;
}

.innerContainer {
  width: 50%;
  margin: 0 auto;
}

.formContainer {
  width: 40%;
  margin-top: 10vh;
}
.detailsListContainer{
  width: 60%;
  margin-top: 7vh;
}
.icon-margin {
  margin-right: 20px;
}

